
<template>
    <div id="printMe" class="container">
      
        <spinner v-if="loading"></spinner> 
        <v-card v-if="item.id">

            
            <v-card-title>
                Booking
                <v-spacer/>
                     <v-btn
                         @click="print"
                        color="green darken-3"
                        dark
                        >
                        <v-icon left>
                            mdi-printer
                        </v-icon>
                        Print
                        </v-btn>
            </v-card-title>

            <v-card-text>
                <div class="row">
                    <div class="col-3">
                        <div style="text-align: left;"><h5>Mango & Lola</h5></div>
                        <div style="text-align: left;">BOOKING: {{item.id}}</div>
                        <div style="text-align: left;">ISSUED: {{item.created_at}}</div>
                    </div>
                    <div class="col-6">
                    </div>
                    <div class="col-3">
                        <div class="row">
                            <div class="mr-2"><b>TO</b></div>
                            <p>{{item.user.name}}</p>                
                        </div>
                    </div>
                    
                </div>
            </v-card-text>


            <v-card-text>
                <template v-for="(i,index) in item.items">
                    
                    <v-card :key="index">
                        <v-card-title>
                            #{{i.quantity}} Piece: {{i.name}}
                            <v-spacer/>
                            {{i.price | currency}}
                        </v-card-title>
                        
                        <v-card-text>{{i.product.description}}</v-card-text>
                        
                        <v-card-actions>
                            From: {{i.product.vendor_name}}
                            <v-spacer/>

                            {{i.type.name}}
                            <v-spacer/>
                            <div v-if="i.tax" class="mr-3">(tax {{i.tax}})</div>
                            <b>{{i.price * i.quantity | currency}}</b>
                        </v-card-actions>

                        <v-divider/>
                    </v-card>
                </template>

            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <b>Total: {{item.total | currency}}</b>
            </v-card-actions>

        </v-card>



    </div>
</template>


<script>


export default {
  middleware: ['auth','vendor'],
  props: ['id'],
  data() {
      return {
          item: [],
          error: null,
          item1: {
              user: {
                  name: ''
              }
          },
          loading: false,
      }
  },
  mounted(){
    this.getItem();  
  },
  methods:{
            getItem()
            {
                this.loading = true;
                let uri = '/api/vendor/bookings/' + this.id ? this.id:this.$route.params.id;
                this.$http.get(uri).then((response) => {
                    this.item = response.data.data
                })
                .catch(error => {
                        this.error = error;
                    })
                .finally(() => this.loading = false);

            },
            print () {
            // Pass the element id here
            this.$htmlToPaper('printMe');
            }
  }
}
</script>







